import { Link } from "react-router-dom";
import {default as nb} from 'react-bootstrap/Navbar';

function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <nb.Brand href="/">TXSubstanceFree</nb.Brand>
                            <Link className="nav-link" aria-current="page" to="/">
                                Home
                            </Link>
                            <Link className="nav-link" to="/About">
                                About
                            </Link>
                            <Link className="nav-link" to="/HelpCenter">
                                Help Centers
                            </Link>
                            <Link className="nav-link" to="/SupportGroup">
                                Support Groups
                            </Link>
                            <Link className="nav-link" to="/DrugType">
                                Drug Types
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}
export default Navbar;
