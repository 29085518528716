import "../pages/Pages.css";

const TeamMemberCard = ({ name, image, commits, issues, bio, responsibility }) => {

    return (
        <div className="team-card">
          <div>
            <img
              src={image}
              alt={name}
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                objectFit: "cover",
                margin: "1rem",
              }}
              />
          </div>
          <div style={{ marginLeft: "1rem", textAlign: "left" }}>
            <h2>{name}</h2>
            <h5 style={{ color: "gray" }}>
                Team Member
            </h5>
            <p><strong>Bio:</strong> {bio}</p>
            <p><strong>Commits:</strong> {commits}</p>
            <p><strong>Issues:</strong> {issues}</p>
            <p><strong>Responsibilites:</strong> {responsibility}</p>
          </div>
        </div>
    );
};

export default TeamMemberCard;