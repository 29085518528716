import { Link } from "react-router-dom";
import "./Card.css";

const HelpCenterCard = ({
  id,
  name,
  city,
  service_settings,
  phone_number,
  image,
  searchQuery,
  searchFilter
}) => {

  const highlightText = (text, filter) => {
    if ((!searchQuery || searchFilter !== filter) && !(searchFilter === "" && filter === "name")) return text;  // If no search query, return the original text

    // Split text into parts around the search query, and wrap the match with a <span> for styling
    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="highlight">{part}</span>
      ) : (
        part
      )
    );
  };



  return (
    <div className="card mb-4" style={{ maxWidth: "400px", margin: "1rem" }}>
      <Link to={"/HelpCenter/" + id} style={{ textDecoration: "none", color: "inherit" }}>
        <img
          src={image}
          alt={name}
          className="card-img-top"
          style={{ maxHeight: "200px", objectFit: "cover" }}
        />
        <div className="card-body">
          <h5 className="card-title">{highlightText(name, "name")}</h5> {/* Highlight name */}
          <p className="card-text">
            <strong>City:</strong> {highlightText(city, "city")}
          </p>
          <p className="card-text">
            <strong>Services Offered:</strong> {service_settings.join(", ")}
          </p>
          <p className="card-text">
            <strong>Phone Number:</strong> {highlightText(phone_number, "phone")}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default HelpCenterCard;
