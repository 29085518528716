import { Link } from "react-router-dom";
import "./Card.css";

const SupportGroupCard = ({
  id,
  groupname,
  location,
  expertise,
  availability,
  phone,
  imgurl,
  searchQuery,
  searchFilter
}) => {

  const highlightText = (text, filter) => {
    if ((!searchQuery || searchFilter !== filter) && !(searchFilter === "" && filter === "name")) return text;  // If no search query, return the original text

    // Split text into parts around the search query, and wrap the match with a <span> for styling
    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="highlight">{part}</span>
      ) : (
        part
      )
    );
  };


  return (
    <div className="card mb-4" style={{ maxWidth: "400px", margin: "1rem" }}>
      <Link to={"/SupportGroup/" + id} style={{ textDecoration: "none", color: "inherit" }}>
        <img
          src={imgurl}
          alt={groupname}
          className="card-img-top"
          style={{ maxHeight: "200px", objectFit: "cover" }}
        />
        <div className="card-body">
          <h5 className="card-title">{highlightText(groupname, "name")}</h5> {/* Highlight name */}
          <p className="card-text">
            <strong>Location:</strong> {highlightText(location, "location")}
          </p>
          <p className="card-text">
            <strong>Expertise:</strong> {highlightText(expertise.join(", "), "expertise")}
          </p>
          <p className="card-text">
            <strong>Availability:</strong> {highlightText(availability, "availability")}
          </p>
          <p className="card-text">
            <strong>Phone Number:</strong> {highlightText(phone, "phone")}
          </p>
        </div>
      </Link>
    </div>
  );
};


export default SupportGroupCard;
