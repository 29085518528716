import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Instance.css";
import SupportGroupCardDyn from "../../components/SupportGroupCardDyn";
import HelpCenterCardDyn from "../../components/HelpCenterCardDyn";
import axios from "axios";
import { useState, useEffect } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function DrugTypeInstance() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data from API...");
      const instance_id = parseInt(id);
      await axios
        .get(
          "https://api.txsubstancefree.me/substancetypes/substanceTypesByID/",
          { params: { id: instance_id } }
        )
        .then((response) => {
          setData(response.data);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
    };
    fetchData();
  }, [id]);

  if (!data) {
    return (
      <div className="container mt-5">
        <div className="loading"> Loading... </div>
      </div>
    );
  }

  // Transform data for each bar chart
  const ageData = Object.entries(data.age || {}).map(([range, value]) => ({
    range,
    value,
  }));
  const incomeData = Object.entries(data.income || {}).map(
    ([range, value]) => ({
      range,
      value,
    })
  );
  const educationData = Object.entries(data.education || {}).map(
    ([level, value]) => ({
      range: level,
      value,
    })
  );
  const ethnicityData = Object.entries(data.race || {}).map(
    ([ethnicity, value]) => ({
      range: ethnicity,
      value,
    })
  );

  return (
    <div className="container mt-5">
      <div className="custom-card">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={data.image}
              alt={data.name}
              className="img-fluid custom-card-img"
            />
          </div>

          <div className="col-md-8">
            <div className="custom-card-body">
              <h1 className="custom-card-title">{data.name}</h1>
              <h4 className="custom-card-subtitle">
                {data.dangerous_substance}
              </h4>
              <p className="custom-card-text">
                {data.summary}
              </p>
              <p className="custom-card-text">
                <strong>Percentage that have ever used:</strong> {data.ever.toFixed(4)}
              </p>
              <p className="custom-card-text">
                <strong>Percentage that have used within 30 days:</strong> {data.thirty_days_rec.toFixed(4)}
              </p>
              <p className="custom-card-text">
                <strong>Average age of first use:</strong> {data.age_first_use}
              </p>
              
                    

              {/* Bar chart for Average Age of Users */}
              {data.age && (
                <div className="chart-section">
                  <p className="custom-card-text">
                    <strong>Average Age of Users:</strong>
                  </p>
                  <ResponsiveContainer width="100%" minWidth={300} aspect={1.8}>
                    <BarChart
                      data={ageData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 60 }}
                    >
                      <XAxis
                        dataKey="range"
                        tick={{ angle: -45, dy: 20 }} // Increased dy for more vertical offset
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#82ca9d" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}

              {/* Bar chart for Average Income of Users */}
              {data.income && (
                <div className="chart-section">
                  <p className="custom-card-text">
                    <strong>Average Income of Users:</strong>
                  </p>
                  <ResponsiveContainer width="100%" minWidth={300} aspect={1.8}>
                    <BarChart
                      data={incomeData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 60 }}
                    >
                      <XAxis
                        dataKey="range"
                        tick={{ angle: -45, dy: 20 }}
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}

              {/* Bar chart for Education Level of Users */}
              {data.education && (
                <div className="chart-section">
                  <p className="custom-card-text">
                    <strong>Education Level of Users:</strong>
                  </p>
                  <ResponsiveContainer width="100%" minWidth={300} aspect={1.8}>
                    <BarChart
                      data={educationData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 60 }}
                    >
                      <XAxis
                        dataKey="range"
                        tick={{ angle: -45, dy: 20 }}
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#ffc658" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}

              {/* Bar chart for Ethnicity of Users */}
              {data.race && (
                <div className="chart-section">
                  <p className="custom-card-text">
                    <strong>Ethnicity of Users:</strong>
                  </p>
                  <ResponsiveContainer width="100%" minWidth={300} aspect={1.8}>
                    <BarChart
                      data={ethnicityData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 60 }}
                    >
                      <XAxis
                        dataKey="range"
                        tick={{ angle: -45, dy: 20 }}
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#82ca9d" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}

              <div className="relevant-cards-container">
                <p className="customer-card-text">
                  <strong>Specialized Help Centers:</strong>
                </p>
                <div className="relevant-card-wrapper">
                  <HelpCenterCardDyn id={data.id % 90} />
                </div>

                <p className="customer-card-text">
                  <strong>Support Groups for This Substance:</strong>
                </p>
                <div className="relevant-card-wrapper">
                  <SupportGroupCardDyn id={data.id % 90} />
                </div>
              </div>

              <Link to="/DrugType" className="btn btn-primary mt-3">
                Back to Drug Types
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrugTypeInstance;
