import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const Map = ({ location }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCbVECPlkT4zYgu5ndu_-Rs9mdYsIRkiJs",
  });

  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (isLoaded && location) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: location }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          setCoordinates({ lat: lat(), lng: lng() });
        } else {
          console.error("Geocode unsuccessful:", status);
        }
      });
    }
  }, [isLoaded, location]);

  return isLoaded ? (
    <div style={{ height: "300px", width: "80%", paddingBottom: "10px" }}>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        zoom={15}
        center={coordinates}
      >
        {coordinates && <Marker position={coordinates} />}
      </GoogleMap>
    </div>
  ) : (
    <p>Loading</p>
  );
};

export default Map;
