import { Link } from "react-router-dom";
import "./Card.css";
import axios from "axios";
import { useState, useEffect } from "react";

const DrugTypeCardDyn = ({ id }) => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // load the data from the API as json
      console.log("Fetching data from API...");
      const instance_id = parseInt(id);
      const data_response = await axios.get('https://api.txsubstancefree.me/substancetypes/substanceTypesByID/', { params: { id: instance_id } })
        .then((response) => {
          setData(response.data);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
      console.log(data_response);
    }

    fetchData();
  }, [id]);

  if (!data) {
    return (
      <div className="container mt-5">
        <div className="loading"> Loading... </div>
      </div>
    );
  }

  return (
    <div className="card mb-4" style={{ maxWidth: "400px", margin: "1rem" }}>
      <Link
        to={"/DrugType/" + id}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <img
          alt={data.name}
          src={data.image}
          className="card-img-top"
          style={{ maxHeight: "200px", objectFit: "cover" }}
        />
        <div className="card-body">
          <h5 className="card-title">{data.name}</h5>
          <p className="card-text">
            <strong>% that have ever used:</strong> {data.ever.toFixed(4)}
          </p>
          <p className="card-text">
            <strong>% regularly use:</strong> {data.thirty_days_rec.toFixed(4)}
          </p>
          <p className="card-text">
            <strong>Average age of first-time users</strong> {data.age_first_use}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default DrugTypeCardDyn;
