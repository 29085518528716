import "./Pages.css";

function Splash() {
  return (
    <div className="splash-container">
      <div className="splash-content">
        <h1 className="splash-title">TX Substance Free</h1>
        <p className="splash-subtitle">
          Supporting Texans on the path to a healthier, substance-free life.
        </p>
      </div>
    </div>
  );
}

export default Splash;
